<template>
  <base-section
    id="slogan"
    class="primary py-5"
  >
    <v-container class="px-4">
        <v-responsive
          class="d-flex align-center mx-auto white--text"
          height="100%"
          max-width="1500"
          width="100%"
        >
          <base-subheading
            size="text-h5"
            weight="regular"
            title='"Our commitment to innovation has made us a leader in nitrile glove manufacturing."'
            class="mx-auto text-uppercase text-center"
            mobileSize="text-h6"
            color="white--text"
          />
        </v-responsive>
      </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionSlogan',
    methods: {
      fnGoToContact () {
        this.$router.push('/contact')
      },
    },
  }
</script>
